import React, { Component } from 'react';
import { Link } from "react-router-dom";

import nyicon1x from './images/ny-icone-1x.png';
import nyicon2x from './images/ny-icone-2x.png';
import nysignature1x from './images/ny-signature-gray-1x.png';
import nysignature2x from './images/ny-signature-gray-2x.png';

import './Menu.css';

class Menu extends Component {

    render() {
        return (
            <header className="navbar navbar-expand-md bg-transparent mt-0 pt-0 mt-sm-1 pt-sm-1 mt-md-2 pt-md-2 mt-lg-2 pt-lg-2">
                <div id="signature" className="navbar-brand">
                    <a href="/">
                        <img src={nyicon1x} alt="Ny" className='img-fluid'
                         sizes="(min-width: 454px) 45px, 30px"
                         srcSet={nyicon1x + ' 43w, ' + nyicon2x + ' 87w'} />
                    </a>&nbsp;&nbsp;
                    <a href="/">
                        <img src={nysignature1x} alt="Ny signature" className='img-fluid'
                         sizes="(min-width: 454px) 289px, 175px"
                         srcSet={nysignature1x + ' 289w, ' + nysignature2x + ' 586w'} />
                    </a>
                </div>

                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon mt-2"><i className="fa fa-navicon  "></i></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active pl-0 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
                            <Link to="/" className="nav-link ny-menu-item"><i
                                className="fas fa-home mr-1"></i>Accueil</Link>
                        </li>
                        <li className="nav-item active pl-0 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
                            <Link to="/expos" className="nav-link ny-menu-item"><i
                                className="fas fa-calendar-alt mr-1"></i>Actualités</Link>
                        </li>
                        <li className="dropdown pr-3">
                            <a className="pl-0 nav-link active dropdown-toggle ny-menu-item" href="# " id="navbarDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-images mr-1"></i>Tableaux&nbsp;
                            </a>
                            <div className="dropdown-menu bg-dark ny-menu-item" aria-labelledby="navbarDropdown">
                                <Link to="/tableaux-l" className="dropdown-item ny-menu-item"><i className="fas fa-image mr-1"></i>&nbsp;en largeur</Link>
                                <Link to="/tableaux-c" className="dropdown-item ny-menu-item"><i className="fas fa-square mr-1"></i>&nbsp;carrés</Link>
                                <Link to="/tableaux-h" className="dropdown-item ny-menu-item"><i className="fas fa-portrait mr-1"></i>&nbsp;en hauteur</Link>
                            </div>
                        </li>
                        <li className="nav-item active pl-0 pr-0 pr-sm-1 pr-md-2 pr-lg-3">
                            <Link to="/contact" className="nav-link ny-menu-item"><i
                                className="fas fa-comment-dots mr-1"></i>Contact</Link>
                        </li>
                    </ul>
                </div>
            </header>
        );
    }
}

export default Menu;