import React, { Component } from 'react';
import './LaUne.css'
import {ImageHelper} from "../gallery/ImageHelper";

import quote from './images/quote.png';

class LaUne extends Component {

    orientedLink:string = '/tableaux-l';
    srcSet:string = '';

    constructor(props) {
        super(props);
        if (window.innerHeight > window.innerWidth) {
            this.orientedLink = '/tableaux-h';
        }
        let randomNumber = ImageHelper.pickPainting();
        this.srcSet = ImageHelper.createSrcSet(randomNumber, 'jpeg');
    }

    render() {
        return (
            <main className="jumbotron p-0 p-md-3 home rounded-0 bg-transparent">
                <div className="container bg-transparent">
                    <picture>
                        <source srcSet={this.srcSet}
                                sizes='(max-width: 1536px) 100vw, 1536px'
                                type="image/jpeg" />
                        <img
                            src={process.env.PUBLIC_URL + '/img/l/jpg/1536/768.jpg'}
                            className="rounded img-fluid img-home" alt="" />
                    </picture>
                </div>
                <div className="card-body bg-transparent ny-quote">
                    <table>
                        <tbody>
                            <tr>
                                <td className="ny-quote-margin"></td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2}>
                                                    <p className="card-text citation-titre pl-3 pr-0 mr-0">&laquo; Peindre, peindre, toujours peindre,
                                                        encore peindre, le mieux possible,
                                                        le vide et le plein, le léger et le dense,
                                                        le vivant et le souffle.&nbsp;&raquo;</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="card-text citation-auteur pl-3">&mdash; Zao Wou-Ki</p>
                                                </td>
                                                <td className="text-right">
                                                    <img src={quote} alt='' className="ny-quote-img" width="40em"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-block d-sm-none">
                        <p className="mt-3 mb-0 pb-0 text-center">
                            <a href={this.orientedLink} className="btn btn-outline-primary waves-effect" role="button"><i
                                className="fas fa-images mr-1"></i>Voir les tableaux</a>
                        </p>
                    </div>
                    <div className="d-none d-sm-block">
                        <p className="mt-3 mb-0 pb-0 text-center">
                            <a href={this.orientedLink} className="btn btn-outline-primary waves-effect btn-lg" role="button"><i
                                className="fas fa-images mr-1"></i>Voir les tableaux</a>
                        </p>
                    </div>
                </div>
            </main>
        );
    }

}

export default LaUne;