import React, { Component } from 'react';
import { Router, Route } from "react-router-dom";

import Menu from "./components/menu/Menu";
import LaUne from './components/laune/LaUne';
import Expos from "./components/expos/Expos";
import PaintingGallery from "./components/gallery/PaintingGallery";
import { ImageHelper} from "./components/gallery/ImageHelper";

import './App.css';

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Contact from "./components/contact/Contact";

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

class App extends Component {

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <Router history={history}>
                <div id="darkbg">
                    <Menu />
                    <Route exact path="/" component={LaUne} />
                    <Route path="/index.html" component={LaUne} />
                    <Route path="/expos" component={Expos} />
                    <Route path="/tableaux-l"
                           render={(props) => <PaintingGallery {...props} paintings={ImageHelper.landscapePaintings} orientation='l' />} />
                    <Route path="/tableaux-c"
                           render={(props) => <PaintingGallery {...props} paintings={ImageHelper.squarePaintings} orientation='c' />} />
                    <Route path="/tableaux-h"
                           render={(props) => <PaintingGallery {...props} paintings={ImageHelper.portraitPaintings} orientation='h' />} />
                    <Route path="/contact" component={Contact} />
                </div>
            </Router>
        );
    }
}

export default App;
