import React, { Component } from 'react';
import './Expos.css';


class Expos extends Component {

    render() {
        return (
            <main className="container-fluid">
                <p>&nbsp;</p>
                <section className="row justify-content-center text-center">

                    <div className="m-1 mb-4 col-10 col-md-6 col-lg-3">
                        <div className="card expo-blue">
                            <img srcSet={process.env.PUBLIC_URL + 'img/expos/png/blue1.png 357w,' + process.env.PUBLIC_URL + 'img/expos/jpg/blue1.jpg 714w'}
                                 className="card-img-top" alt="Illusion" />
                            <div className="card-body">
                                <p className="card-text expo-titre1">28 sept au 6 oct 2019</p>
                                <h5 className="card-title expo-titre">Mennecy</h5>
                                <p className="card-text">Salon Petit Format 2019</p>
                                <a href="http://www.lesartistesdenotreregion.fr" target="_blank" rel="noopener noreferrer">Voir le site de
                                    l'exposition <i className="fas fa-external-link-alt"></i></a>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Salle Michel-Ange, 7 avenue du Villeroy</small><br />
                                <small className="text-muted">91540 Mennecy</small>
                            </div>
                        </div>
                    </div>

                    <div className="m-1 mb-4 col-10 col-md-6 col-lg-3">
                        <div className="card expo-orange">
                            <img srcSet={process.env.PUBLIC_URL + 'img/expos/png/orange1.png 357w,' + process.env.PUBLIC_URL + 'img/expos/jpg/orange1.jpg 714w'}
                                 className="card-img-top" alt="Ikebana" />
                            <div className="card-body">
                                <p className="card-text expo-titre1">11 au 27 octobre 2019</p>
                                <h5 className="card-title expo-titre">Melun</h5>
                                <p className="card-text">Salon d'Arts Plastiques des Amis des Arts</p>
                                <a href="https://www.amisdesarts-77.com/" target="_blank" rel="noopener noreferrer">Voir le site de
                                    l'exposition <i className="fas fa-external-link-alt"></i></a>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Espace
                                    Saint-Jean : 26 place Saint-Jean</small><br />
                                <small className="text-muted">77000 Melun</small>
                            </div>
                        </div>
                    </div>

                    <div className="m-1 mb-4 col-10 col-md-6 col-lg-3">
                        <div className="card expo-blue">
                            <img srcSet={process.env.PUBLIC_URL + 'img/expos/png/blue2.png 357w,' + process.env.PUBLIC_URL + 'img/expos/jpg/blue2.jpg 714w'}
                                 className="card-img-top" alt="Illusion" />
                            <div className="card-body">
                                <p className="card-text expo-titre1">Octobre 2019</p>
                                <h5 className="card-title expo-titre">Vert-le-Petit</h5>
                                <p className="card-text">Exposition personnelle</p>
                                <a href="http://www.vertlepetit.fr/mediatheque/" target="_blank" rel="noopener noreferrer">Voir le site de
                                    l'exposition <i className="fas fa-external-link-alt"></i></a>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Médiathèque : 9 rue de la Liberté</small><br />
                                <small className="text-muted">91710 Vert-le-Petit</small>
                            </div>
                        </div>
                    </div>

                    <div className="m-1 mb-4 col-10 col-md-6 col-lg-3">
                        <div className="card expo-orange">
                            <img srcSet={process.env.PUBLIC_URL + 'img/expos/png/orange2.png 357w,' + process.env.PUBLIC_URL + 'img/expos/jpg/orange2.jpg 714w'}
                                 className="card-img-top" alt="Ikebana4" />
                            <div className="card-body">
                                <p className="card-text expo-titre1">16 au 24 novembre 2019</p>
                                <h5 className="card-title expo-titre">Boissise-Le-Roi</h5>
                                <p className="card-text">Exposition personnelle</p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Château-Mairie, 11 rue du Château</small><br />
                                <small className="text-muted">77310 Boissise-Le-Roi</small>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        );
    }

}

export default Expos;