import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './PaintingGallery.css';
import { ImageHelper} from "./ImageHelper";

interface PaintingGalleryProps {
    paintings: string[];
    orientation: string;
}

class PaintingGallery extends Component<PaintingGalleryProps> {

    private galleryImages: any[] = [];

    constructor(props: PaintingGalleryProps) {
        super(props);

        for (const item of this.props.paintings) {
            this.galleryImages.push(ImageHelper.createGalleryImage(item, this.props.orientation));
        }
        //console.log('images = ' + JSON.stringify(this.galleryImages));
    }

    render() {
        return (
                <ImageGallery
                    items={this.galleryImages}
                    slideDuration={800}
                    slideInterval={4000}
                    thumbnailPosition={'bottom'}
                />
        );
    }
}

export default PaintingGallery;