import React, { Component } from 'react';
import './Contact.css';

import quote from './images/quote.png';

class Contact extends Component {

    render() {
        return (
            <main className="jumbotron p-0 p-md-3 home rounded-0 bg-transparent">
                <div className="card-body bg-transparent ny-quote">
                    <table>
                        <tbody>
                        <tr>
                            <td className="ny-quote-margin"></td>
                            <td>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <p className="card-text contact-titre pl-3 pr-0 mr-0">&laquo; Les peintures d'Anne-Marie Gaume, plutôt ascensionnelles, manifestent l'enlevé, ce qui est une position moyenne, heureuse, toute de légèreté. Toutefois, la configuration la plus fréquente oppose deux régions aux tempos inégaux : une région où prévaut la lenteur, où règne l'étendu, l'étalé, qui correspondrait à l'a-plat dans la terminologie traditionnelle, et une région agitée, souvent confuse, où domine le pressé. Ici le ton est toujours en devenir et suit un trajet, une déclinaison de degrés qui conduit du bleu profond à un vert clair proche de l'extinction, ou bien de l'ocre au rouge en passant par l'orangé vif. Il semble d'ailleurs que le traitement de la couleur soit autant calorique que proprement chromatique et compose l'ardeur de la rougeur avec la fraîcheur de la bleuité. Nous dirons que le figuratif dans certaines toiles d'Anne-Marie Gaume n'est jamais très loin : un figuratif naturaliste, latent, et qu'il suffirait de quelques touches judicieuses pour qu'il soit changé en paysages et en bouquets. De là sans doute l'impression dominante en présence de ces toiles d'assister à une apparition, de guetter une imminence, de surprendre une émergence. Les peintres sont aussi à l'écoute du temps.&nbsp;&raquo;</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="card-text contact-auteur pl-3">&mdash; Claude Zilberberg (sémioticien et linguiste)</p>
                                        </td>
                                        <td className="text-right">
                                            <img src={quote} alt='' className="ny-quote-img" width="40em"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </main>
        );
    }
}

export default Contact;